import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ScrollSync } from 'scroll-sync-react';
import { postRequest } from "../../utils/httpUtils";
// import TableDetails from "../../utils/TableDetails";
import customSyncPlugin from "../../utils/syncUtil";
import { Viewer } from "../../utils";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import Typed from "react-typed";
import TableDetails from "../../utils/TableDetails";
import { consistencyCheckHighlightUtil } from "../../utils/highlightUtil";
import ConsistencyCheckViewers from "./ConsistencyCheckViewers";
// import dummy from "./dummy.json"
import { Trigger } from "@react-pdf-viewer/highlight";

export default function Results({ files, appInstanceID }) {
  const [tables, setTables] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [viewMode, setViewMode] = useState("ui");
  const customSyncPluginInstance = customSyncPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const [triggerType, setTriggerType] = useState(Trigger.TextSelection)
  const [annotatedPdfData, setAnnotatedPdfData] = useState({
    file1: {
      previousYear: null,
      trackedChanges: null,
      mathAccuracy: null,
      highlightedAreas: [],
    },
    file2: {
      previousYear: null,
      trackedChanges: null,
      highlightedAreas: [],
    },
  });
  const [isScrollDisabled, setIsScrollDisabled] = useState(false)
  const [isAnnotating, setIsAnnotating] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [dates, setDates] = useState({});
  const [isParsing, setIsParsing] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState()
  const [checkOptions, setCheckOptions] = useState({
    previousYear: true,
    trackedChanges: false,
    mathAccuracy: false
  });
  const [processedDates, setProcessedDates] = useState({
    currentYear: null,
    previousYear: null,
    sortedFileIds: {
      current: null,
      previous: null,
    },
  });
  const [annotatedPdfFiles, setAnnotatedPdfFiles] = useState({
    previousYear: {
      file1: null,
      file2: null
    },
    trackedChanges: {
      file1: null,
      file2: null
    },
    mathAccuracy: {
      file1: null,
      file2: null
    }
  });

  // Page nav and highlight plugins for viewers
  const PNPDF1PluginInstance = pageNavigationPlugin();
  const PNPDF2PluginInstance = pageNavigationPlugin();
  const { jumpToPage: JTP1 } = PNPDF1PluginInstance;
  const { jumpToPage: JTP2 } = PNPDF2PluginInstance;
  const HLPDF1PluginInstance = consistencyCheckHighlightUtil(
    [annotatedPdfData?.file1?.highlightedAreas, annotatedPdfData?.file2?.highlightedAreas],
    annotatedPdfData?.file1?.url,
    triggerType,
    1
  );
  const HLPDF2PluginInstance = consistencyCheckHighlightUtil(
    [annotatedPdfData?.file2?.highlightedAreas, annotatedPdfData?.file1?.highlightedAreas],
    annotatedPdfData?.file2?.url,
    triggerType,
    2
  );

  // Fetch tables data
  useEffect(() => {
    const getTables = async () => {
      try {
        setIsParsing(true); 
        const response = await postRequest(
          "/api/footnote_ai/consistency_check",
          {
            files,
            app_instance_id: appInstanceID,
          }
        );
        
        const { footnotes, dates } = response.data.data;
        // const { footnotes, dates } = dummy.data;

        // console.log("Tables:", footnotes);
        // console.log("Dates:", dates);
        setTables(footnotes);
        setDates(dates);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
      finally {
        setIsParsing(false); 
      }
    };
    getTables();
  }, [files, appInstanceID]);

  useEffect(() => {
    if (!Object.keys(dates).length) return;

    const processDatesData = () => {
      // Process dates to get years and sort file IDs
      const datesArray = Object.entries(dates).map(([fileId, dateStr]) => ({
        fileId,
        date: new Date(dateStr),
        year: new Date(dateStr).getFullYear(),
      }));

      // Sort by year descending (most recent first)
      datesArray.sort((a, b) => b.year - a.year);

      setProcessedDates({
        currentYear: datesArray[0].year,
        previousYear: datesArray[1].year,
        sortedFileIds: {
          current: datesArray[0].fileId,
          previous: datesArray[1].fileId,
        },
      });
    };

    processDatesData();
  }, [dates]);

  // useEffect(() => {
  //   const handleComparison = async () => {
  //     if (viewMode === "tracked" && !comparisonPdfUrl) {
  //       await createComparisonPdf();
  //     }
  //   };

  //   handleComparison();
  // }, [viewMode, comparisonPdfUrl, tables]);

  useEffect(() => {
    if (viewMode !== "ui") return;

    // console.log("Check Options:", checkOptions);
    const generatePDFs = async () => {
      try {

        const processedData = {};
        Object.entries(tables).forEach(([footnoteTitle, files]) => {
          const comparisons = files?.comparisons?.results || null;
          const verifications = files?.verify_total?.results || null;
          processedData[footnoteTitle] = processComparisonsAndVerifications(comparisons, verifications);
        });
        if (
          checkOptions.previousYear &&
          (!annotatedPdfFiles?.previousYear?.file1 ||
            !annotatedPdfFiles?.previousYear?.file2)
        ) {
          await createPreviousYearCheckPdf();
        } else if (
          checkOptions.trackedChanges &&
          (!annotatedPdfFiles?.trackedChanges?.file1 ||
            !annotatedPdfFiles?.trackedChanges?.file2)
        ) {
          await createTrackedChangesPdf();
        } else if (
          checkOptions.mathAccuracy &&
          (!annotatedPdfFiles?.mathAccuracy?.file1 ||
            !annotatedPdfFiles?.mathAccuracy?.file2)
        ) {
          await createSummationCheckPdf();
        }
      } catch (error) {
        console.error("Error generating PDFs:", error);
      }
    };

    if (Object.keys(tables).length > 0) {  // Only run if tables data is available
      generatePDFs();
    }
  }, [checkOptions, viewMode,tables]);

  const handleCheckboxChange = (option) => {
    console.log("Selected Checkbox option:", option);
    setSelectedItemIndex(undefined)
    setCheckOptions({
      previousYear: option === "previousYear",
      trackedChanges: option === "trackedChanges",
      mathAccuracy: option === "mathAccuracy",
    });
    setTriggerType(option === "mathAccuracy" ? Trigger.None : Trigger.TextSelection)
  };

  const createPreviousYearCheckPdf = async () => {
    if (viewMode !== "ui") return;
    // Skip if already generated
    if (
      annotatedPdfFiles.previousYear.file1 &&
      annotatedPdfFiles.previousYear.file2
    ) {
      return;
    }
    try {
      setIsAnnotating(true);

      // Create maps for both files
      const file1AnnotationsMap = new Map();
      const file2AnnotationsMap = new Map();

      // Process each footnote entry
      Object.entries(tables).forEach(([footnoteTitle, files]) => {
        if (!files.comparisons?.results) return;

        // Get file entries
        const fileEntries = Object.entries(files).filter(
          ([key]) => key !== "comparisons" && key !== "verify_total"
        );

        if (fileEntries.length < 2) return;

        const [file1ArrayString, file1Values] = fileEntries[0];
        const [file2ArrayString, file2Values] = fileEntries[1];
        const [, file1ID] = JSON.parse(file1ArrayString);
        const [, file2ID] = JSON.parse(file2ArrayString);
        const file1Data = JSON.parse(file1Values[0]);
        const file2Data = JSON.parse(file2Values[0]);

        // Process each comparison result
        files.comparisons.results.forEach((comp) => {
          // File 1 data
          const [row1, col1] = comp.position[0];
          const cell1 = file1Data.cells?.find(
            (cell) => cell.col === col1 && cell.row === row1
          );

          // File 2 data
          const [row2, col2] = comp.position[1];
          const cell2 = file2Data.cells?.find(
            (cell) => cell.col === col2 && cell.row === row2
          );

          if (cell1 && cell2) {
            const key1 = `${cell1.page_number}-${cell1.top}-${cell1.left}-${comp.value1}`;
            const key2 = `${cell2.page_number}-${cell2.top}-${cell2.left}-${comp.value2}`;

            // All comparisons are added with their is_equal status
            if (!file1AnnotationsMap.has(key1)) {
              file1AnnotationsMap.set(key1, {
                value: comp.value1,
                is_equal: comp.is_equal,
                page_number: cell1.page_number,
                color: comp.is_equal ? [0,1,0.94]: [1,0.70,0.75], 
                bounding_box: {
                  top: cell1.top,
                  left: cell1.left,
                  width: cell1.width,
                  height: cell1.height,
                  page_width: cell1.page_width,
                  page_height: cell1.page_height,
                },
              });
            }

            if (!file2AnnotationsMap.has(key2)) {
              file2AnnotationsMap.set(key2, {
                value: comp.value2,
                is_equal: comp.is_equal,
                page_number: cell2.page_number,
                color: comp.is_equal ? [0,1,0.94] : [1,0.70,0.75],
                bounding_box: {
                  top: cell2.top,
                  left: cell2.left,
                  width: cell2.width,
                  height: cell2.height,
                  page_width: cell2.page_width,
                  page_height: cell2.page_height,
                },
              });
            }
          }
        });

        window.file1ID = file1ID;
        window.file2ID = file2ID;
      });

      console.log("File 1 Annotations Map:", file1AnnotationsMap);
      console.log("File 2 Annotations Map:", file2AnnotationsMap);

      // Create annotated PDFs for both files
      const [file1Response, file2Response] = await Promise.all([
        postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
          file_id: window.file1ID,
          footnote_title: "Previous Year Comparison",
          comparisons: Array.from(file1AnnotationsMap.values()),
        }),
        postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
          file_id: window.file2ID,
          footnote_title: "Previous Year Comparison",
          comparisons: Array.from(file2AnnotationsMap.values())
        })
      ]);

      const _prevYear1 = Array.from(file1AnnotationsMap.values())
      const _prevYear2 = Array.from(file2AnnotationsMap.values())

      const hlArea1 = _prevYear1.map(cell => ({
        value: cell.value,
        pageIndex: cell.page_number - 1,
        height: (cell.bounding_box.height / cell.bounding_box.page_height) * 100,
        width: (cell.bounding_box.width / cell.bounding_box.page_width) * 100,
        left: (cell.bounding_box.left / cell.bounding_box.page_width) * 100,
        top: (cell.bounding_box.top / cell.bounding_box.page_height) * 100,
        active: false,
      }))

      const hlArea2 = _prevYear2.map(cell => ({
        value: cell.value,
        pageIndex: cell.page_number - 1,
        height: (cell.bounding_box.height / cell.bounding_box.page_height) * 100,
        width: (cell.bounding_box.width / cell.bounding_box.page_width) * 100,
        left: (cell.bounding_box.left / cell.bounding_box.page_width) * 100,
        top: (cell.bounding_box.top / cell.bounding_box.page_height) * 100,
        active: false,
      }))

      setAnnotatedPdfData(prev => ({
        file1: {
          ...prev.file1,
          previousYear: _prevYear1,
          highlightedAreas: hlArea1
        },
        file2: {
          ...prev.file2,
          previousYear: _prevYear2,
          highlightedAreas: hlArea2
        },
      }))

      setAnnotatedPdfFiles(prev => ({
        ...prev,
        previousYear: {
          file1: file2Response.data?.annotated_file || null,
          file2: file1Response.data?.annotated_file || null,
        }
      }));

    } catch (error) {
      console.error("Error creating previous year PDFs:", error);
    } finally {
      setIsAnnotating(false);
    }
  };

  const createTrackedChangesPdf = async () => {
    if (viewMode !== "ui") return;
    if (
      annotatedPdfFiles.trackedChanges.file1 &&
      annotatedPdfFiles.trackedChanges.file2
    ) {
      return;
    }
    try {
      setIsComparing(true);
      // Get file entries from the first footnote
      const firstEntry = Object.entries(tables)[0];
      if (!firstEntry) return;

      const [_, files] = firstEntry;
      const fileEntries = Object.entries(files).filter(
        ([key]) => key !== "comparisons" && key !== "verify_total"
      );
      if (fileEntries.length < 2) return;

      // Get file IDs from the entries
      const file1ID = JSON.parse(fileEntries[0][0])[1];
      const file2ID = JSON.parse(fileEntries[1][0])[1];

      // Make comparison request with PDF output
      const response = await postRequest("/api/footnote_ai/compare_and_highlight_pdf_diff", {
        file1_id: file1ID,
        file2_id: file2ID,
        output_type: "pdf",
      });
      if (response.data?.files) {
        
        // Process highlights similar to how comparisons are processed
        const file1HighlightMap = new Map();
        const file2HighlightMap = new Map();

        response.data.highlights.file1.forEach(highlight => {
            const key = `${highlight.page_number}-${highlight.bounding_box.top}-${highlight.bounding_box.left}-${highlight.value}`;
            file1HighlightMap.set(key, highlight);
        });

        response.data.highlights.file2.forEach(highlight => {
            const key = `${highlight.page_number}-${highlight.bounding_box.top}-${highlight.bounding_box.left}-${highlight.value}`;
            file2HighlightMap.set(key, highlight);
        });

        const _trackChanges1 = Array.from(file1HighlightMap.values())
        const _trackChanges2 = Array.from(file2HighlightMap.values())

        const hlArea1 = _trackChanges1.map(cell => ({
          value: cell.value,
          pageIndex: cell.page_number - 1,
          height: (cell.bounding_box.height / cell.bounding_box.page_height) * 100,
          width: (cell.bounding_box.width / cell.bounding_box.page_width) * 100,
          left: (cell.bounding_box.left / cell.bounding_box.page_width) * 100,
          top: (cell.bounding_box.top / cell.bounding_box.page_height) * 100,
          active: false,
        }))

        const hlArea2 = _trackChanges2.map(cell => ({
          value: cell.value,
          pageIndex: cell.page_number - 1,
          height: (cell.bounding_box.height / cell.bounding_box.page_height) * 100,
          width: (cell.bounding_box.width / cell.bounding_box.page_width) * 100,
          left: (cell.bounding_box.left / cell.bounding_box.page_width) * 100,
          top: (cell.bounding_box.top / cell.bounding_box.page_height) * 100,
          active: false,
        }))

        setAnnotatedPdfData(prev => ({
          file1: {
            ...prev.file1,
            trackedChanges: _trackChanges1,
            highlightedAreas: hlArea1
          },
          file2: {
            ...prev.file2,
            trackedChanges: _trackChanges2,
            highlightedAreas: hlArea2
          },
        }))

        setAnnotatedPdfFiles((prev) => ({
          ...prev,
          trackedChanges: {
            file1: response.data.files.file2 || null,
            file2: response.data.files.file1 || null,
          },
        }));
      }
      else {
        console.error("No files in response data:", response.data);
      }
    } catch (error) {
      console.error("Error creating tracked changes PDFs:", error);
    } finally {
      setIsComparing(false);
    }
  };

  const createSummationCheckPdf = async () => {
    if (viewMode !== "ui") return;
    if (
      annotatedPdfFiles.mathAccuracy.file1 
    ) {
      return;
    }
    try {
      setIsAnnotating(true);

      const file1VerificationsMap = new Map();
      const file1VerificationsMapForView = new Map();

      Object.entries(tables).forEach(([footnoteTitle, files]) => {
        if (!files.verify_total?.results) return;

        const fileEntries = Object.entries(files).filter(
          ([key]) => key !== "comparisons" && key !== "verify_total"
        );

        if (fileEntries.length < 2) return;

        const [file1ArrayString, file1Values] = fileEntries[0];
        const [, file1ID] = JSON.parse(file1ArrayString);
        const file1Data = JSON.parse(file1Values[0]);

        files.verify_total.results.forEach((verify) => {
          if (verify.table === "table_one") {
            const [row, col] = verify.position[0];
            const cell = file1Data.cells?.find(
              (cell) => cell.col === col && cell.row === row
            );
            if (cell) {
              const key = `${cell.page_number}-${cell.top}-${cell.left}-verify`;
              const value = {
                value: verify.is_equal ? "^" : "Incorrect",
                page_number: cell.page_number,
                bounding_box: {
                  top: cell.top + 0.24,
                  left: (cell.left + cell.width) * 0.99,
                  width: cell.width * 0.1,
                  height: cell.height * 0.8,
                  page_width: cell.page_width,
                  page_height: cell.page_height,
                },
                annotation_type: "point",
                color: verify.is_equal ? [0,1,0.94]: [1,0.70,0.75],
              }
              file1VerificationsMap.set(key, value);
              file1VerificationsMapForView.set(key, {
                ...value,
                value: verify.value,
                disableHighlight: true,
              });
            }
          }
        });

        window.file1ID = file1ID;
      });

      const file1Response = await postRequest("/api/footnote_ai/annotate_pdf_after_comparison", {
        file_id: window.file1ID,
        footnote_title: "Summation Check",
        comparisons: Array.from(file1VerificationsMap.values()),
        differences: [],
      });
      console.log("File 1 response:", file1Response.data);
      setAnnotatedPdfFiles((prev) => ({
        ...prev,
        mathAccuracy: {
          file1: file1Response.data?.annotated_file || null,
          file2: file1Response.data?.annotated_file || null,
        },
      }));

      setAnnotatedPdfData(prev => ({
        file1: {
          ...prev.file1,
          mathAccuracy: Array.from(file1VerificationsMapForView.values())
        },
        file2: {
          ...prev.file2,
        },
      }))

    } catch (error) {
      console.error("Error creating summation check PDFs:", error);
    } finally {
      setIsAnnotating(false);
    }
  };

  const getActivePDFs = () => {
    if (!annotatedPdfFiles) return { file1: null, file2: null };
    if (checkOptions.previousYear && annotatedPdfFiles.previousYear) {
      return annotatedPdfFiles.previousYear;
    }
    if (checkOptions.trackedChanges && annotatedPdfFiles.trackedChanges) {
      return annotatedPdfFiles.trackedChanges;
    }
    if (checkOptions.mathAccuracy && annotatedPdfFiles.mathAccuracy) {
      return annotatedPdfFiles.mathAccuracy;
    }

    return { file1: null, file2: null };
  };

  const processComparisonsAndVerifications = (comparisons, verifications) => {
    const cellsToHighlight = {
      0: [],
      1: [],
    };

    const verifiedCells = {
      0: [], // Verified cells for first table
      1: [], // Verified cells for second table
    };

    if (comparisons) {
      comparisons.forEach((item) => {
        const color =
          item.is_equal === true
            ? "#90EE90"
            : item.is_equal === false
            ? "#FF7F7F"
            : "#CCCCCC";

        const [table1Row, table1Col] = item.position[0];
        cellsToHighlight[0].push([table1Row, table1Col, color]);

        const [table2Row, table2Col] = item.position[1];
        cellsToHighlight[1].push([table2Row, table2Col, color]);
      });
    }

    // Handle verifications
    if (verifications) {
      verifications.forEach((item) => {
        const tableIndex = item.table === "table_one" ? 0 : 1;
        const [row, col] = item.position[0];
        verifiedCells[tableIndex].push([row, col, item.is_equal]);
      });
    }
    
    return { cellsToHighlight, verifiedCells };
  };

  const renderSideBySideComparison = () => {
    return Object.entries(tables).map(([footnoteTitle, files], i) => {
      const comparisons = files?.comparisons?.results || null;
      const verifications = files?.verify_total?.results || null;
      const { cellsToHighlight, verifiedCells } = processComparisonsAndVerifications(comparisons, verifications);

      return (
        <div key={footnoteTitle} className="mb-8">
          <h1 className="text-xxl font-bold mb-4 text-center">
            {footnoteTitle}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8" style={{ direction: "rtl" }}>
            {Object.entries(files).map(([fileArrayString, arrayValues], tableIndex) => {
              if (fileArrayString === "comparisons" || fileArrayString === "verify_total") return null;

              const [fileName, fileID] = JSON.parse(fileArrayString);

              return (
                <div key={fileArrayString} className="border rounded-lg p-4">
                  <h3 className="text-md mb-4 text-center font-semibold">
                    {fileName}
                  </h3>
                  <div className="mb-6">
                    <div className="border rounded overflow-hidden">
                      <Viewer
                        fileUrl={`/api/files/${fileID}.pdf`}
                        plugins={[pageNavigationPluginInstance]}
                        onDocumentLoad={() => {
                          if (arrayValues?.[0]) {
                            const parsedData = JSON.parse(arrayValues[0]);
                            if (parsedData.cells?.[0]) {
                              jumpToPage(parsedData.cells[0].page_number - 1);
                            }
                          }
                        }}
                        scale={2.0}
                      />
                    </div>
                  </div>
                  <div className="border rounded p-4 bg-white">
                    <TableDetails
                      tableDetails={JSON.parse(arrayValues[0]).cells}
                      highlights={cellsToHighlight[tableIndex]}
                      tableVerifiedCells={verifiedCells[tableIndex]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="my-8" />
        </div>
      );
    });
  };

  const highlightViewers = (key, index) => {

    setSelectedItemIndex(index)
    setIsScrollDisabled(true)

    if (annotatedPdfData.file1?.[key]) {
      const _t1 = annotatedPdfData.file1?.[key]?.[index];
      const pageNumber1 = _t1.page_number - 1;
      const _h1 = annotatedPdfData.file1?.highlightedAreas;
      if (!_t1?.disableHighlight) {
        // const _highlightedAreas1 = {
        //   pageIndex: pageNumber1,
        //   height: (_t1.bounding_box.height / _t1.bounding_box.page_height) * 100,
        //   width: (_t1.bounding_box.width / _t1.bounding_box.page_width) * 100,
        //   left: (_t1.bounding_box.left / _t1.bounding_box.page_width) * 100,
        //   top: (_t1.bounding_box.top / _t1.bounding_box.page_height) * 100,
        // };

        // console.log(_highlightedAreas1)

        setAnnotatedPdfData(prev => ({
          ...prev,
          file1: {
            ...prev.file1,
            highlightedAreas: _h1?.map((h, ix) => ({ ...h, active: (ix === index) }))
          },
        }))
      }
      console.log('pageNumber1', pageNumber1)

      JTP1(pageNumber1)
    }

    if (annotatedPdfData.file2?.[key]) {
      const _t2 = annotatedPdfData.file2?.[key]?.[index];
      const pageNumber2 = _t2.page_number - 1;
      const _h2 = annotatedPdfData.file2?.highlightedAreas;
      if (!_t2?.disableHighlight) {
        // const _highlightedAreas2 = {
        //   pageIndex: pageNumber2,
        //   height: (_t2.bounding_box.height / _t2.bounding_box.page_height) * 100,
        //   width: (_t2.bounding_box.width / _t2.bounding_box.page_width) * 100,
        //   left: (_t2.bounding_box.left / _t2.bounding_box.page_width) * 100,
        //   top: (_t2.bounding_box.top / _t2.bounding_box.page_height) * 100,
        // }

        // console.log(_highlightedAreas2)

        setAnnotatedPdfData(prev => ({
          ...prev,
          file2: {
            ...prev.file2,
            highlightedAreas: _h2?.map((h, ix) => ({ ...h, active: (ix === index) }))
          },
        }))
      }

      console.log('pageNumber2', pageNumber2)
      JTP2(pageNumber2)
    }
  }

  const checkOptionKey = Object.entries(checkOptions).find(co => co.includes(true))[0] || "previousYear"
  const activePDFs = getActivePDFs();

  const getSortedFileEntries = () => {
    const firstEntry = Object.entries(tables)[0];
    let sortedFileEntries = []
    if (firstEntry) {
      const [_, _files] = firstEntry;
      const fileEntries = Object.entries(_files)
        .filter(([key]) => key !== "comparisons" && key !== "verify_total")
        .slice(0, 2);
      // Sort fileEntries to match our date order
      sortedFileEntries = fileEntries.sort(([aKey], [bKey]) => {
        const aFileId = JSON.parse(aKey)[1];
        const bFileId = JSON.parse(bKey)[1];
        if (aFileId === processedDates.sortedFileIds.current) return -1;
        if (bFileId === processedDates.sortedFileIds.current) return 1;
        return 0;
      });
    }
    return sortedFileEntries
  }

  const renderConsistencyCheckViewers = () => (
    <ScrollSync disabled={isScrollDisabled}>
      <ConsistencyCheckViewers
        plugins={[
          [
            HLPDF2PluginInstance,
            PNPDF2PluginInstance,
            customSyncPluginInstance,
          ],
          [
            HLPDF1PluginInstance,
            PNPDF1PluginInstance,
            customSyncPluginInstance,
          ]
        ]}
        activePDFs={activePDFs}
        isComparing={isComparing}
        isAnnotating={isAnnotating}
        checkOptions={checkOptions}
        processedDates={processedDates}
        checkOptionKey={checkOptionKey}
        highlightViewers={highlightViewers}
        annotatedPdfData={annotatedPdfData}
        sortedFileEntries={getSortedFileEntries()}
        selectedItemIndex={selectedItemIndex}
        isScrollDisabled={isScrollDisabled}
        setIsScrollDisabled={setIsScrollDisabled}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
      />
    </ScrollSync>
  )

  const renderUIView = () => {
    return !isFullScreen && (
      <div className="flex flex-col">
        <div className="border-b py-2">
          <div className="flex justify-between">
            <div className="border rounded-lg p-4 shadow-md w-1/2">
              <div className="mb-2">
                <input
                  type="checkbox"
                  id="py-check"
                  checked={checkOptions.previousYear}
                  onChange={() => handleCheckboxChange("previousYear")}
                />
                <label htmlFor="py-check" className="ml-2">
                  Previous Year Check
                </label>
              </div>

              <div className="mb-2">
                <input
                  type="checkbox"
                  id="tracked-changes"
                  checked={checkOptions.trackedChanges}
                  onChange={() => handleCheckboxChange("trackedChanges")}
                />
                <label htmlFor="tracked-changes" className="ml-2">
                  Tracked Changes
                </label>
              </div>

              <div>
                <input
                  type="checkbox"
                  id="math-accuracy"
                  checked={checkOptions.mathAccuracy}
                  onChange={() => handleCheckboxChange("mathAccuracy")}
                />
                <label htmlFor="math-accuracy" className="ml-2">
                  Mathematical Accuracy
                </label>
              </div>
            </div>

            <div className="border rounded-lg p-4 shadow-md w-1/2">
              <div className="flex items-center gap-2 mb-2">
                <div className="w-4 h-4 bg-customBlue-100 rounded"></div>
                <span>Matching Text</span>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <div className="w-4 h-4 bg-customRed-100 rounded"></div>
                <span>Non-matching Text</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 text-gray-600">✔️</div>
                <span>Mathematical Accuracy</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-1 my-4 sm:-mr-[1.5rem] h-[600px] overflow-y-scroll">
          { renderConsistencyCheckViewers() }
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="py-4 relative">
        {/* <div className="mb-4 flex justify-center">
          <div className="inline-flex rounded-lg border border-gray-200">
            <button
              className={`px-6 py-2 rounded-l-lg ${
                viewMode === "comparison"
                  ? "bg-customHighlightColor text-white"
                  : "bg-white text-gray-700"
              }`}
              onClick={() => setViewMode("comparison")}
            >
              Side-by-Side Comparison
            </button>
            <button
              className={`px-6 py-2 rounded-r-lg ${
                viewMode === "ui"
                  ? "bg-customHighlightColor text-white"
                  : "bg-white text-gray-700"
              }`}
              onClick={() => setViewMode("ui")}
            >
              UI
            </button>
          </div>
        </div> */}

        {/* {viewMode === "comparison"
          ? renderSideBySideComparison()
          : renderUIView()} */}
        {isParsing && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-50 w-full h-full">
            <div className="text-gray-600 text-xl">
              Working through and comparing documents
              <Typed strings={["..."]} loop typeSpeed={40} />
            </div>
          </div>
        )}

        {renderUIView()}
      </div>

      <Transition.Root show={isFullScreen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setIsFullScreen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-100"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-2xl bg-white p-4 text-left shadow-xl transition-all w-full h-[calc(100vh-6rem)] sm:p-6"
                >
                  <div className="flex gap-2 my-4 sm:-mr-[1.5rem] h-full overflow-y-scroll">
                    { renderConsistencyCheckViewers() }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ReactTooltip />
    </>
  );
}